.personsTable {
    table-layout:auto;
    text-align: left;
    vertical-align: middle;
}

.personsTable td {
    text-align: left;
}

.personsTable tr:hover{
    /*background-color: #c41864;*/
    background-color: #f7af1b;
}

.rightButton {
    text-align: right;
}