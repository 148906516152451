/*@import '~antd/lib/style/themes/default.less';*/
/*@import '~antd/dist/antd.less'; /* Import Ant Design styles by less entry*/
@import 'my-theme-file.less'; /* variables to override above*/

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 0vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
}

.questionContainer {
  padding: 12px 10px;
}
.questionCard {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 5px; /* 5px rounded corners */
  line-height: 1.6;
}

.questionResponse{
  display: flex;
}

.questionText{
  text-align: left;
  flex-wrap: wrap;
  width: 99%;
  line-height: 360%;
}
.questionScoring{
  display: flex;
  flex-wrap: wrap;
  width: 70%;
  min-width: 300px;
}
.questionIgnoreCheckBox{
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  width: 70%;
  line-height: 360%;
}
.questionCommentButton{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  text-align: right;
  /*align-items: end;*/
  width: 30%;
  margin-right: 25px;
}
.questionComment{
  flex-direction: row-reverse;
  
  position: relative;
  text-align: right;
  /*align-content: stretch;*/
  vertical-align: top;
}

.pageInstructions {
  /*box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);*/
  box-shadow: 0 4px 8px 0 rgba(112,66,45,1);
  transition: 0.3s;
  border-radius: 5px; /* 5px rounded corners */
  padding: 12px 10px;
  text-align:left;
  border-color: rgb(112,66,45);
  border-width: 3px;
}

.buttonTable {
  width: 100%;
}

.buttonContainer {
  /*padding: 12px 10px;*/
  padding: 0% 30%;
}

.pageLeftButton {
  width:20%;
  align-items:right;
  display: inline-block;
}
.pageMiddleButton {
  width:20%;
  align-items:left;
  display: inline-block;
}
.pageRightButton{
  width: 20%;
  align-items:left;
  display: inline-block;
}
.apexcharts-legend-series{
  text-align: left;
}
.apexcharts-legend-marker{
  float: left;
}
.apexcharts-legend-text{
  float: left;
}

.checkLabel{ 
  font-size: 60%, 
}

.reportContainer {
  display: grid;
  grid-template-columns: 47% 5% 47%;
  padding: 25px;
}
.reportItem {
  padding: 25px;
  border: solid 1px #aaaaaa;
  background-color: #fefefe;
  box-shadow: 5px -5px 2px  #999999;
  -moz-box-shadow: 5px -5px 2px  #999999;
  -webkit-box-shadow: 5px -5px 2px  #999999;
  -o-box-shadow: 5px -5px 2px  #999999;
  border-radius:25px;
}

.nav-link {
  color: white;
}
.navmenu-link{
  color:  #1890ff;
}

.list-sort{
/*  max-width: 480px;*/
  margin:auto;
}
.list-item{
  display:flex;
  align-items: center;
  background-color: #FFD2D2;
  padding:0px 20px;
  margin-top:15px;
  cursor:move;
  transition: all .2s ease-in-out;

}
.list-item-draggable{
  display:flex;
  align-items: center;
  background-color: #FFD2D2;
  padding:0px 20px;
  margin-top:15px;
  

}
.list-item:hover{
  scale:1.01;

}