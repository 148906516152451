body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.progressText{
  color: red;
}

.questionText{
  /*color: blue;*/
  text-align: right;
}
.answerOptions{
  text-align: left;
}
.instructions td{
  align-self:center;
  text-align: left;
  border-style:solid;
  border-width: thin;
}

.subjectsTable {
  width: 100%
}

tr:nth-child(even) {background-color: #f2f2f2;}
tr td:nth-child(1) { /* I don't think they are 0 based */
  text-align: left;
}
.languageLink[disabled] {
  color: red;
  text-decoration: none;
}

.languageLink:hover {
  color: white;
  text-decoration: underline;
}

.languageLink {
  color: white;
  font-size: large;
}
