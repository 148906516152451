.edit-respondent-form label,
.edit-respondent-form input {
    display: block;
    text-align: left;
    width: 100%;
    margin: 5px auto;
    padding: 5px;
    box-sizing: border-box;
    font-size: 0.8em;
}

/*div.bottomButtons {
    width: 66%;
}*/
div.bottomButtons > div {
    display: inline-block;
    zoom: 1; /* Trigger hasLayout */
    width: 33%;
    text-align: center;
}