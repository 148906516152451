.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    z-index: 1000;
}

.modal {
    padding: 30px;
    max-width: 480px;
    margin: 100px auto;
    /* margin-left: 200px auto;
    margin-top:100px auto;*/
    background: #fff;
    border-radius: 10px;
}

.modal-wide {
    padding: 10px;
    max-width: 1200px;
    margin: 30px auto;
    /* margin-left: 200px auto;
    margin-top:100px auto;*/
    background: #fff;
    border-radius: 10px;
}